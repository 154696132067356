/**
 * AjaxLoader
 */

import React from 'react';
import s from './AjaxLoader.module.scss';

class AjaxLoader extends React.Component {
    render() {
        return (
            <div className={s['AjaxLoader']}>
                <svg className={s['AjaxLoader__Circular']} viewBox="25 25 50 50">
                    <circle
                        className={s['AjaxLoader__Path']}
                        cx="50"
                        cy="50"
                        r="20"
                        fill="none"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                    />
                </svg>
            </div>
        );
    }
}

export default AjaxLoader;
