/**
 * HeaderMenu
 */

import React, {useState, useEffect, useRef, useContext, Fragment} from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import Headroom from 'react-headroom';
import {ucFirst} from 'utils/caseconverters';
import {isIos} from 'utils/Helpers';
import AppContext from 'Containers/Layouts/AppContext';
import ArrowDownIcon from 'Assets/inline-svg/arrow-down.svg';
import ExternalLinkIcon from 'Assets/inline-svg/external.svg';
import HeaderSubMenu from 'Components/HeaderSubMenu';
import HeaderSearch from 'Components/HeaderSearch';
import Button from 'Components/Button';
import Browsealoud from 'Components/Browsealoud';
import Logo from 'Components/Logo';
import s from './HeaderMenu.module.scss';

const HeaderMenu = ({
    menu = [],
    menuService = [],
    disableGoogleTranslate,
    searchPageUrl,
    disableHeadroom,
    browsealoudScript,
}) => {
    const {currentTheme} = useContext(AppContext);

    const [openSubMenuHref, setOpenSubMenuHref] = useState(undefined);
    const [showTranslationOptions, setShowTranslationOptions] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [isPinned, setIsPinned] = useState(true);

    const handleSubNavigationOnMouseEnter = (href) => {
        setOpenSubMenuHref(openSubMenuHref !== href ? href : undefined);
    };

    const handleTranslationOptionsOnClick = () => {
        setShowTranslationOptions(!showTranslationOptions);
    };

    const handleHeaderOnPin = () => {
        document.body.classList.add('js-state-headroom-pinned');
        setIsPinned(true);
    };

    const toggleSearchBarVisibility = () => {
        setShowSearchModal(!showSearchModal);
    };

    const handleHeaderOnUnpin = () => {
        document.body.classList.remove('js-state-headroom-pinned');
        setIsPinned(false);
    };

    const handleResetTranslationOnClick = () => {
        const selectElements = document.getElementsByClassName('goog-te-combo');

        if (!selectElements || selectElements.length === 0) {
            return;
        }

        let index = 0;
        for (let i = 0; i < selectElements[0].length; i++) {
            if (selectElements[0][i].value === 'sv') {
                index = i;
            }
        }

        selectElements[0].selectedIndex = index;
        selectElements[0].dispatchEvent(new Event('change'));
    };

    useEffect(() => {
        const selectElements = document.getElementsByClassName('goog-te-combo');
        if(selectElements.length && showTranslationOptions === false) {
            selectElements[0].dispatchEvent(new Event('change'));
        }
    }, [showTranslationOptions]);

    // Move service menu to top if items exist
    const hasServiceMenu = !!menuService && menuService.length > 0;

    const classes = classNames(
        s['HeaderMenu'],
        {[s['HeaderMenu--OpenSubMenu']]: openSubMenuHref},
        {[s['HeaderMenu--Pinned']]: isPinned},
        {[s['HeaderMenu--HasServiceMenu']]: hasServiceMenu},
        {[s[`HeaderMenu--${ucFirst(currentTheme)}`]]: currentTheme},
    );

    return (
        <Fragment>
            {(openSubMenuHref ||
                showSearchModal ||
                showTranslationOptions) && (
                    <div
                        className={s['HeaderMenu__Overlay']}
                        onClick={() => {
                            handleSubNavigationOnMouseEnter(undefined);
                        }}
                    />
                )}
            <Headroom
                style={{
                    zIndex: 2
                }}
                disable={disableHeadroom || (showSearchModal && isIos())}
                onPin={handleHeaderOnPin}
                onUnpin={handleHeaderOnUnpin}
            >
                <header className={classes}>
                    {hasServiceMenu && (
                        <MenuService
                            menuService={menuService}
                            searchPageUrl={searchPageUrl}
                            disableGoogleTranslate={disableGoogleTranslate}
                            handleResetTranslationOnClick={handleResetTranslationOnClick}
                            handleTranslationOptionsOnClick={handleTranslationOptionsOnClick}
                            showTranslationOptions={showTranslationOptions}
                            toggleSearchBarVisibility={toggleSearchBarVisibility}
                            browsealoudScript={browsealoudScript}
                        />
                    )}
                    <div className={s['HeaderMenu__Container']}>
                        <Link
                            className={s['HeaderMenu__LogoLink']}
                            href="/"
                            aria-label="Till startsidan"
                        >
                            <Logo
                                className={s['HeaderMenu__Logo']}
                                modifier={currentTheme === 'sensus' ? 'White' : ''}
                            />
                        </Link>

                        <nav
                            className={s['HeaderMenu__Links']}
                            aria-label="Primär meny"
                        >
                            {menu && menu.length > 0 && (
                                <Fragment>
                                    {menu.map((item, index) => (
                                        <HeaderMenuItem
                                            openSubMenuHref={openSubMenuHref}
                                            handleSubNavigationOnMouseEnter={handleSubNavigationOnMouseEnter}
                                            key={index}
                                            {...item}
                                        />
                                    ))}
                                </Fragment>
                            )}
                        </nav>

                        <nav
                            className={s['HeaderMenu__MenuExtras']}
                            aria-label={hasServiceMenu ? 'Mobil sekundär meny' : 'Sekundär meny'}
                        >
                            <Extras
                                disableGoogleTranslate={disableGoogleTranslate}
                                handleResetTranslationOnClick={handleResetTranslationOnClick}
                                handleTranslationOptionsOnClick={handleTranslationOptionsOnClick}
                                toggleSearchBarVisibility={toggleSearchBarVisibility}
                                showTranslationOptions={showTranslationOptions}
                                showSearchModal={showSearchModal}
                                searchPageUrl={searchPageUrl}
                                browsealoudScript={browsealoudScript}
                            />
                        </nav>

                        {showSearchModal && (
                            <HeaderSearch
                                searchPageUrl={searchPageUrl}
                                toggleSearchBarVisibility={toggleSearchBarVisibility}
                                showSearchModal={showSearchModal}
                                hasServiceMenu={hasServiceMenu}
                            />
                        )}
                    </div>
                </header>
            </Headroom>
        </Fragment>
    );
};

const Extras = ({
    modifier = '',
    disableGoogleTranslate,
    handleResetTranslationOnClick,
    handleTranslationOptionsOnClick,
    toggleSearchBarVisibility,
    showTranslationOptions,
    showSearchModal,
    searchPageUrl,
    browsealoudScript,
}) => {
    const translationRef = useRef();

    const translationWrapperClasses = classNames(
        s['HeaderMenu__TranslateModal'],
        {[s['HeaderMenu__TranslateModal--Visible']]: showTranslationOptions},
    );

    const searchClasses = classNames(
        s['HeaderMenu__Search'],
        {[s['HeaderMenu__Search--Active']]: showSearchModal},
    );

    return (
        <>
            <div className={s['HeaderMenu__Extras']}>
                {browsealoudScript &&
                    <Browsealoud
                        browsealoudScript={browsealoudScript}
                        modifiers={['Desktop', modifier]}
                    />
                }

                {!disableGoogleTranslate && (
                    <TranslateModal
                        handleResetTranslationOnClick={
                            handleResetTranslationOnClick
                        }
                        translationModal={translationRef.current}
                        handleTranslationOptionsOnClick={handleTranslationOptionsOnClick}
                        classes={translationWrapperClasses}
                    />
                )}

                {searchPageUrl && (
                    <Fragment>
                        <button
                            type="button"
                            onClick={toggleSearchBarVisibility}
                            className={searchClasses}
                            aria-label="Visa sökfält"
                            aria-controls="header-search"
                            aria-expanded={
                                showSearchModal
                                    ? 'true'
                                    : 'false'
                            }>
                            <span>Sök</span>
                        </button>
                    </Fragment>
                )}
            </div>
        </>
    );
};

const MenuService = ({
    menuService,
    disableGoogleTranslate,
    handleResetTranslationOnClick,
    handleTranslationOptionsOnClick,
    toggleSearchBarVisibility,
    showTranslationOptions,
    showSearchModal,
    searchPageUrl,
    browsealoudScript,
}) => {
    return (
        <nav className={s['HeaderMenu__MenuService']} aria-label="Sekundär meny">
            <div className={s['HeaderMenu__MenuServiceWrapper']}>
                {menuService.length > 0 && menuService.map((item, index) => (
                    <a
                        key={index}
                        href={item.url}
                        className={s['HeaderMenu__MenuServiceLink']}
                    >
                        {item.text}
                    </a>
                ))}

                <Extras
                    modifier="MenuService"
                    disableGoogleTranslate={disableGoogleTranslate}
                    handleResetTranslationOnClick={handleResetTranslationOnClick}
                    handleTranslationOptionsOnClick={handleTranslationOptionsOnClick}
                    toggleSearchBarVisibility={toggleSearchBarVisibility}
                    showTranslationOptions={showTranslationOptions}
                    showSearchModal={showSearchModal}
                    searchPageUrl={searchPageUrl}
                    browsealoudScript={browsealoudScript}
                />
            </div>
        </nav>
    );
};

const TranslateModal = ({
    handleTranslationOptionsOnClick,
    classes,
    translationModal,
    handleResetTranslationOnClick,
}) => (
    <Fragment>
        <div className={s['HeaderMenu__TranslateButtonWrapper']}>
            <button
                type="button"
                onClick={handleTranslationOptionsOnClick}
                className={s['HeaderMenu__Translate']}>
                Translate
            </button>

            <div className={s['HeaderMenu__TranslateTooltip']}>
                Translate this page
            </div>
        </div>

        <div className={classes} ref={translationModal}>
            <div className={s['HeaderMenu__TranslateWrapper']}>
                <button
                    type="button"
                    className={s['HeaderMenu__TranslateClose']}
                    aria-label="Close translation"
                    onClick={handleTranslationOptionsOnClick}
                />

                <h3 className={s['HeaderMenu__TranslateTitle']}>
                    Translate this site
                </h3>

                <p className={s['HeaderMenu__TranslateDescription']}>
                    Select your language in the dropdown menu below
                </p>

                <div id="google-translate-modal"></div>

                <div className={classNames(s['HeaderMenu__TranslateDisableButton'], s['notranslate'])}>
                    <Button
                        type="button"
                        onClick={handleResetTranslationOnClick}
                        modifier={'Fullwidth'}
                        text={'Turn off translation'}
                    />
                </div>
            </div>
        </div>
    </Fragment>
);

const HeaderMenuItem = ({
    externalLinkHref,
    externalLinkTitle,
    page,
    items,
    openSubMenuHref,
    handleSubNavigationOnMouseEnter,
    active,
}) => {
    const isExternalLink = externalLinkHref && externalLinkTitle && Object.keys(page).length === 0;
    const link = {
        href: isExternalLink ? externalLinkHref : page.href,
        title: isExternalLink ? externalLinkTitle : page.title,
    };

    if (!items || !items.length) {
        const classes = classNames(
            s['HeaderMenu__LinkItem'],
            {[s['HeaderMenu__LinkItem--active']]: active},
            {[s['HeaderMenu__LinkItem--External']]: isExternalLink},
        );
        return (
            <div className={classes}>
                <a
                    href={link.href}
                    target={isExternalLink ? '_blank' : null}
                    rel={isExternalLink ? 'noopener noreferrer' : ''}
                    className={classNames(
                        s['HeaderMenu__Link'],
                        s['HeaderMenu__Link--NoMenu'],
                        {[s['HeaderMenu__Link--External']]: isExternalLink},
                    )}
                >
                    {link.title}
                    {isExternalLink && (
                        <ExternalLinkIcon
                            className={s['HeaderMenu__ExternalIcon']}
                            fill="currentColor"
                        />
                    )}

                </a>
            </div>
        );
    }

    const isOpen = openSubMenuHref === link.href;

    const classes = classNames(
        s['HeaderMenu__LinkItem'],
        s['HeaderMenu__LinkItem--Menu'],
        {[s['HeaderMenu__LinkItem--active']]: active},
        {[s['HeaderMenu__LinkItem--Open']]: isOpen},
    );

    return (
        <div
            className={classes}
            onMouseEnter={() => {
                handleSubNavigationOnMouseEnter(link.href);
            }}
            onMouseLeave={() => {
                handleSubNavigationOnMouseEnter(undefined);
            }}>
            <a
                href={link.href}
                className={classNames(
                    s['HeaderMenu__Link'],
                    s['HeaderMenu__Link--Menu'],
                    {[s['HeaderMenu__Link--External']]: isExternalLink},
                )}
            >
                {link.title}
                <ArrowDownIcon
                    className={s['HeaderMenu__Arrow']}
                    fill="currentColor"
                />
            </a>

            <HeaderSubMenu
                promotion={!isExternalLink ? page.promotion : undefined}
                items={items}
                visible={openSubMenuHref === page.href}
                isOpen={isOpen}
            />
        </div>
    );
};

export default HeaderMenu;
