import { httpPostWithCsrfToken, httpGet } from './Http';

function required(value) {
    return value ? undefined : 'Obligatoriskt.';
}

function isYes(value) {
    return value === 'yes' ? undefined : 'Du måste svara ja för att komma vidare';
}

function zipCode(value) {
    let error = 'Ditt postnummer skall vara 5 siffror';

    if (!/[0-9]{5}/.test(value)) {
        return error;
    }

    return undefined;
}

function ssnLength(value) {
    let error = 'Ditt personnummer är inte korrekt formaterat (ÅÅÅÅMMDD-XXXX)';

    if (/[^0-9-\s]+/.test(value)) {
        return error;
    }

    value = value.replace(' ', '');
    value = value.replace('-', '');

    if (value.length !== 12) {
        return error;
    }

    return undefined;
}

function lmaLength(value) {
    let error = 'Ditt LMA- eller samordningsnummer måste vara 8 siffror';

    value = value.replace(' ', '');
    value = value.replace('-', '');

    if (/\D/.test(value)) {
        return error;
    }

    if (value.length !== 8) {
        return error;
    }

    return undefined;
}

function over18(value) {
    // if (value.length < 8) {
    //     return;
    // }

    let year = value.substring(0, 4);
    let month = value.substring(4, 6);
    let day = value.substring(6, 8);

    if (getAge(year + '/' + month + '/' + day) < 18) {
        return 'Du måste vara över 18 år som målsman.';
    }

    return undefined;
}

function getAge(dateString) {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

function luhn(value) {
    value = value.substring(2, value.length);

    let error = 'Ditt personnummer är inte giltigt.';
    if (/[^0-9-\s]+/.test(value)) {
        return error;
    }

    let nCheck = 0;
    let bEven = false;
    value = value.replace(/\D/g, '');

    for (let n = value.length - 1; n >= 0; n--) {
        let cDigit = value.charAt(n);
        let nDigit = parseInt(cDigit, 10);

        if (bEven) {
            if ((nDigit *= 2) > 9) {
                nDigit -= 9;
            }
        }

        nCheck += nDigit;
        bEven = !bEven;
    }

    return nCheck % 10 === 0 ? undefined : error;
}

function email(value) {
    let error = 'Din epost är inte korrekt formaterad.';

    if (!/^\S+@\S+\.[a-zA-Z]+$/.test(value)) {
        return error;
    }

    if (!value) {
        return error;
    }

    return undefined;
}

const asyncValidate = (values, dispatch, props) => {
    const { validateApiUrl, validateRequestType } = props;
    const { email } = values;
    const postData = {
        email
    };

    const url = validateApiUrl ? validateApiUrl : '/api/v2/gustav/validate';
    const requestType = validateRequestType ? validateRequestType : 'POST';
    const httpFunc = requestType === 'POST' ? httpPostWithCsrfToken : httpGet;

    return httpFunc(url, postData).then((responseData) => {
        const { email } = responseData;
        if (email && email !== "debug@debug.com") {
            throw {email};
        }
    });
};

export {
    required,
    isYes,
    luhn,
    email,
    ssnLength,
    lmaLength,
    over18,
    zipCode,
    asyncValidate,
};
